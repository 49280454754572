import React, { useContext, useEffect, useState } from "react";
import Header from "./Layouts/Header";
import axios from "axios";
import { BaseUrl, ImageBaseUrl } from "../App";
import { AuthContext } from "../navigation/AuthProvider";

const RateCard = (rate) => {

    console.log("Rate Card:::::", rate);

    return (
        <div style={{ padding: '20px', margin: '20px', backgroundColor: '#fff', boxShadow: '0px 0px 10px #ECA6A6', borderRadius: '8px', overflow: 'hidden' }}>
            <h3>{rate?.rate?.material}</h3>
            <hr />
            {rate?.rate?.isCarat === "0" ? (
                <div>
                    <h5>Price: ₹ {rate?.rate?.price} /gm</h5>
                </div>
            ) : (
                <div>
                    <h5>18 carat: ₹ {rate?.rate?.price_18K} /gm</h5>
                    <hr />
                    <h5>22 carat: ₹ {rate?.rate?.price_22K} /gm</h5>
                    <hr />
                    <h5>24 carat: ₹ {rate?.rate?.price_24K} /gm</h5>
                </div>
            )}
        </div>
    )
}

const RateCardPage = () => {

    const [rateCard, setRateCard] = useState([]);

    const getRateCard = async () => {
        await axios.post(`${BaseUrl}/get_all_material_price`).then((res) => {
            if (res.status === 200) {
                console.log("Rate Card Response ::::::", res.data.rates);
                setRateCard(res.data.rates);
            } else {
                alert(`Error in Fetching Rate Card | Status: ${res.status}`);
            }
        }).catch((error) => {
            console.log("Error in Fetching Rate Card", error);
            alert(`Error in Fetching Rate Card | Error: ${error}`);
        });
    }

    useEffect(() => {
        getRateCard();
    }, []);

    return (
        <div>
            <Header />
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                {rateCard.map((rate, index) => {
                    console.log("Rate Card in map:::::", rate);
                    return (
                        <RateCard rate={rate} />
                    )
                })}
            </div>
        </div>
    );
};

export default RateCardPage;