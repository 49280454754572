import logo from '../logo.svg';
import React, { lazy, Suspense } from 'react';
import '../App.css';

import {
  HashRouter,
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Inquiry from '../Templates/Inquiry';
import MyInquies from '../Templates/MyInquies';
import RateCard from '../Templates/RateCard';
import RateCardPage from '../Templates/RateCard';


const Header = React.lazy(() => import("../Templates/Layouts/Header"));
const Footer = React.lazy(() => import("../Templates/Layouts/Footer"));
const Home = React.lazy(() => import("../Templates/HomePage/Home"));
const Page404 = React.lazy(() => import("../Templates/Layouts/Page404"));
const Categories = React.lazy(() => import("../Templates/Categories"));
const Product = React.lazy(() => import("../Templates/ProductDetails"));
const Cart = React.lazy(() => import("../Templates/Cart"));
const Checkout = React.lazy(() => import("../Templates/CheckoutPage"));
const Wishlist = React.lazy(() => import("../Templates/Wishlist"));
const Thanku = React.lazy(() => import("../Templates/Thanku"));
const Faq = React.lazy(() => import("../Templates/Faq"));
const ContactUs = React.lazy(() => import("../Templates/ContactUs"));

const OrderDetails = React.lazy(() => import("../Templates/Orders/OrderDetails"));
const ExchangeOrder = React.lazy(() => import("../Templates/Orders/ExchangeOrder"));
const ReturnOrder = React.lazy(() => import("../Templates/Orders/ReturnOrder"));
const TrackOrder = React.lazy(() => import("../Templates/Orders/TrackOrder"));

const Products = React.lazy(() => import("../Templates/Products"));
const Orders = React.lazy(() => import("../Templates/Orders/Transactions"));
const PrivacyPolicy = React.lazy(() => import("../Templates/PrivacyPolicy"));
const TermsOfService = React.lazy(() => import("../Templates/TermsOfService"));
const ShippingHandling = React.lazy(() => import("../Templates/ShippingHandling"));
const HelpSupport = React.lazy(() => import("../Templates/Help"));
const Profile = React.lazy(() => import("../Templates/Profile/Profile"));

function AppStack() {
  return (
    <HashRouter>
      <Suspense
        fallback={
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center', marginTop: window.innerHeight / 2.5 }}>
            <div className="lds-facebook"></div>
          </div>
        }
      >
        {/* <Header /> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/category/:id" element={<Categories />} />
          {/* <Route exact path="/product" element={<Product />} /> */}
          <Route exact path="/product/:id" element={<Product />} />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/checkout" element={<Checkout />} />
          <Route exact path="/wishlist" element={<Wishlist />} />
          <Route exact path="/thanku" element={<Thanku />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          {/* <Route exact path="/add-address" element={<AddAddress />} /> */}
          <Route exact path="/order-details" element={<OrderDetails />} />
          <Route exact path="/return-order" element={<ReturnOrder />} />
          <Route exact path="/exchange-order" element={<ExchangeOrder />} />
          <Route exact path="/track-order" element={<TrackOrder />} />

          <Route exact path="/products" element={<Products />} />
          <Route exact path="/sortby/:price" element={<Products />} />
          <Route exact path="/orders" element={<Orders />} />
          <Route exact path="/profile" element={<Profile />} />
          
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-of-service" element={<TermsOfService />} />
          <Route exact path="/shipping-handing" element={<ShippingHandling />} />
          <Route exact path="/Help-&-Support" element={<HelpSupport />} />

          <Route exact path="/inquiry" element={<Inquiry />} />
          <Route exact path='/my_inquiries' element={<MyInquies />} />
          <Route exact path='/rate_card' element={<RateCardPage />} />

          <Route path="*" element={<Page404 />} />
        </Routes>
        {/* <Footer /> */}
      </Suspense>
    </HashRouter>
  );
}

export default AppStack;
