import React from 'react'
import Provider from './navigation/Index'

// export const BaseUrl = 'https://shopninja.in/sunaar24/api2/public/index.php'
export const BaseUrl = 'https://sunaar24.com/api2/public/index.php'
export const ImageBaseUrl = 'https://sunaar24.com/'
function App() {
  return (
    <Provider />
  )
}

export default App