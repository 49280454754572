import React, { useState, useContext, useEffect } from 'react'
import '../../css/style.css'
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../../navigation/AuthProvider';

function Header() {

  const userId = localStorage.getItem('idtoken')
  let navigate = useNavigate()
  const location = useLocation()

  const { userToken, logout, userDetails, fetching, appData } = useContext(AuthContext)

  const [query, setquery] = useState('')
  const [SearchArr, setSearchArr] = useState([])
  const [searchShow, setSearchShow] = useState(false)
  const [focus, setFocus] = useState(false)

  // const logout = () => {
  //   sessionStorage.clear()
  //   navigate('/login')
  //   window.location.reload()
  // }

  const handleSearch = (inputvalue) => {
    if (inputvalue == "") {
      setSearchArr([])
      setSearchShow(false)
      return
    }
    setSearchShow(true)
    inputvalue = inputvalue.toLowerCase()
    var PaintingArr = appData?.products?.filter((element => {
      var lowercase = element.product_name.toLowerCase().split(" ").join("");


      return lowercase.includes(inputvalue) || element.product_name.toLowerCase().includes(inputvalue)
    }))


    // var ArtistArr = Catagory?.artists?.filter((element=>{
    //   var lowercase = element.name.toLowerCase().split(" ").join("");

    //   return lowercase.includes(inputvalue) ||  element.name.toLowerCase().includes(inputvalue);
    // }))

    setSearchArr([...PaintingArr])
  }


  const debounce = (func, delay) => {
    let timer
    return (...args) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        func(...args)  // func.apply(null,args)     
      }, delay)
    }
  }


  const filerSearch = debounce((inputvalue) => handleSearch(inputvalue), 300)

  useEffect(() => {

    setSearchShow(false)
  }, [location?.pathname]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ padding: "10px 30px", border: '1px solid #aaa' }}>
        <div className="container-fluid">
          <a className="navbar-brand" href="/" style={{
            color: "#ECA6A6", fontFamily: "Roboto, sans-serif",
            fontSize: 32, marginLeft: '10%'
          }}>
            {/* <img src="images/logo.webp" alt="logo" style={{ height: 70, }}></img> */}
            Sunaar24
          </a>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse justify-content-evenly" id="navbarSupportedContent">

            <div className="searchbaron1000 d-flex justify-content-between" style={{ margin: '0 auto', position: 'relative', width: '100%', flex: 1, gridGap: 20 }} onClick={() => setSearchShow(!searchShow)}>

              <div style={{ maxWidth: '300px', width: '100%', position: 'relative', display: 'flex' }} >
                <input type="text" className="form-control" id="search" placeholder="Search bar "
                  style={{ backgroundColor: 'transparent', border: '1px solid #cccccc' }}
                  onChange={(e) => filerSearch(e.target.value)}

                  autocomplete="off"
                />
                <i class="bi bi-search" Style={'position:absolute;right:10px;font-size:1.2rem !important;top:2.5px;cursor:pointer;'}></i>
                {searchShow && (<div className="searchdropdown" >
                  {SearchArr.length ? SearchArr?.map((element) => {
                    {/* console.log(typeof element) */ }
                    return <div onClick={() => navigate("/product/" + element.product_slug)}>
                      <img src={typeof element?.images == "string" ? element.images : element.images[0]}></img>
                      <p>{element.product_name}</p>
                      <p>&#x20B9; {element?.product_weight * element?.market_price[Object.keys(element?.market_price)[0]]}</p>
                    </div>
                  }) : <div>no product found</div>}
                </div>)}
              </div>

            </div>

            <ul className="navbar-nav me-end mb-2 mb-lg-0 text-center">
              {/* <li className="nav-item headerLink">
              <a className="nav-link " aria-current="page" href="/" style={{ fontFamily: 'Roboto, sans-serif', }}>Home</a>
            </li> */}
              <li className="nav-item headerLink" style={{}}>
                <a className="nav-link" href="#/wishlist" style={{ fontFamily: 'Roboto, sans-serif', position: 'relative' }}>
                  <i className="bi bi-heart" style={{ fontSize: 20, }}></i>
                  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {appData.wishlist?.length}
                    <span class="visually-hidden">unread messages</span>
                  </span>
                  <span className='headerBtnTxt'>Wishlist</span>
                </a>
              </li>
              <li className="nav-item headerLink" style={{}}>
                <a className="nav-link" href="#/cart" style={{ fontFamily: 'Roboto, sans-serif', position: 'relative' }}>
                  <i className="bi bi-cart3" style={{ fontSize: 20, }}></i>
                  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {appData.cart?.length}
                    <span class="visually-hidden">unread messages</span>
                  </span>
                  <span className='headerBtnTxt'>Cart</span>
                </a>
              </li>

              <li className="nav-item headerLink">
                <a className="nav-link" href="#/inquiry" style={{ fontFamily: 'Roboto, sans-serif' }}>
                  <i className="bi bi-chat-dots" style={{ fontSize: 20, }}></i>
                </a>
              </li>

              <li className="nav-item headerLink">
                <a className="nav-link" href="#/rate_card" style={{ fontFamily: 'Roboto, sans-serif' }}>
                  <i className="bi bi-tags" style={{ fontSize: 20, }}></i>
                </a>
              </li>

              {
                userToken ?
                  <li class="nav-item dropdown headerLink">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"
                      style={{ fontFamily: 'Roboto, sans-serif' }}>
                      <img src="images/login_img.png" alt="pic" style={{
                        height: 30, width: 30, borderRadius: 30, marginRight: 5,
                      }}></img>  {userDetails?.cust_name}
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown" style={{ padding: 15, paddingTop: 20 }}>

                      <div className='row'>
                        <div className='col-md-3 alignCenter'>
                          <img src="images/login_img.png" alt="pic" style={{
                            height: 60, width: 60, borderRadius: 30, marginRight: 10,
                          }}></img>
                        </div>
                        <div className='col-md-9' style={{ textAlign: 'center' }}>
                          <span style={{ fontSize: 20, fontWeight: '800', color: '#000', fontFamily: 'Roboto, sans-serif' }}>{userDetails.cust_name}</span><br></br>
                          <a className='darkLink altDarkLink' href='#/profile' style={{ margin: 0 }}>See your profile</a>
                        </div>
                      </div>

                      <li style={{ marginTop: 20 }}><hr class="dropdown-divider"></hr></li>

                      <div style={{ margin: 15 }}>
                        <a className='darkLink altDarkLink' href='#/orders' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 0 }}>
                          <i class="bi bi-card-list" style={{ marginRight: 20, fontWeight: 'bold', fontSize: 18 }}></i>
                          Orders</a>
                      </div>

                      <div style={{ margin: 15 }}>
                        <a className='darkLink altDarkLink' href='#' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 0 }}>
                          <i className="bi bi-gear" style={{ marginRight: 20, fontWeight: 'bold', fontSize: 18 }}></i>
                          Settings</a>
                      </div>

                      <div style={{ margin: 15 }}>
                        <a className='darkLink altDarkLink' href='#/Help-&-Support' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 0 }}>
                          <i className="bi bi-question-circle" style={{ marginRight: 20, fontWeight: 'bold', fontSize: 18 }}></i>
                          Help & Support</a>
                      </div>

                      <div style={{ margin: 15 }}>
                        <div className='darkLink altDarkLink' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 0 }}
                          onClick={() => logout(navigate)}>
                          <i className="bi bi-box-arrow-right" style={{ marginRight: 20, fontWeight: 'bold', fontSize: 18 }}></i>
                          Logout</div>
                      </div>

                      {/* <li><a class="dropdown-item" href="#">Action</a></li>
                    <li><a class="dropdown-item" href="#">Another action</a></li>
                    <li><a class="dropdown-item" href="#">Something else here</a></li> */}
                    </ul>
                  </li>
                  :
                  <a href="#/login" className="themeButton">Login</a>
              }
            </ul>
            {/* {userId ? null :
            <a href="#/login" className="themeButton">Login</a>
          } */}
          </div>

        </div>
      </nav >

      <div className='row' style={{ backgroundColor: '#ECA6A6', margin: 0, padding: '10px 10%' }}>
        <div className='col-md-2 headerBorders' style={{ textAlign: 'center' }}>
          <Link to='/products' className='loginFormLink' href='#/category'>All Jewellery</Link>
        </div>
        {appData?.categories?.slice(0, 5).map((element) => {

          return <div className='col-md-2 headerBorders' style={{ textAlign: 'center' }} id='navigation-item'>
            <Link to={"/category/" + element?.cat_id} className='loginFormLink' href='#/category'>{element?.category_name}
              {element?.sub_cat?.length ?
                <div className="nav-item-hover">

                  <div>
                    <h4 className='section-subheading'>Sub Category</h4>
                    <div className="d-flex" style={{ flexWrap: 'wrap', gridGap: 30 }}>

                      {element?.sub_cat?.map((item) => {

                        return <a className="normal-text link-a">{item?.category_name}</a>
                      })}

                    </div>
                  </div>




                </div>
                :
                null}




            </Link>
          </div>
        })}

      </div>

    </div >
  )
}

export default Header