import React, { useContext, useState } from "react";
import Header from "./Layouts/Header";
import axios from "axios";
import { BaseUrl, ImageBaseUrl } from "../App";
import { AuthContext } from "../navigation/AuthProvider";
import { Link } from "react-router-dom";

const Inquiry = () => {

    const { userToken } = useContext(AuthContext);

    const [inquryData, setInquryData] = useState({
        user_id: userToken,
        weight: "",
        metal: "",
        purpose: "",
        description: "",
        urls: []
    });

    const handleInquryData = (e) => {
        const { name, value } = e.target;
        setInquryData({ ...inquryData, [name]: value });
    }

    const handleImageUpload = async (e) => {

        const confirm = window.confirm("Are you sure you want to upload Image?");
        if (!confirm) {
            e.target.value = null;
            return;
        }

        const files = Array.from(e.target.files);
        const formData = new FormData();

        files.forEach((file, index) => {
            formData.append("images[]", file);
        });


        await axios.post(`${BaseUrl}/upload_images`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            console.log("Image Uploaded Successfully", res.data);
            if (res.status === 200) {
                alert("Image Uploaded Successfully");
                setInquryData({ ...inquryData, urls: res.data.image_urls });
            }
        }).catch((error) => {
            console.log("Error in Image Upload", error);
        });

        // const files = Array.from(e.target.files);
        // setInquryData({ ...inquryData, urls: files }); // to replace with new images
        // setInquryData((prev) => { return { ...prev, image: [...prev.image, ...files] } }) // to append multiple images
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("inquryData before submit::::", inquryData);

        const confirm = window.confirm("Are you sure you want to submit Inquiry?");
        if (!confirm) { return; }


        await axios.post(`${BaseUrl}/submit_query`, inquryData).then((res) => {
            console.log("Inquiry Submitted Successfully", res.data);

            if (res.status === 200) {
                setInquryData({
                    user_id: userToken,
                    weight: "",
                    metal: "",
                    purpose: "",
                    description: "",
                    image: []
                });
                alert("Inquiry Submitted Successfully");
            } else {
                alert(`Error in Inquiry  | status: ${res.status}`);
            }
        }).catch((error) => {
            console.log("Error in Inquiry Submit", error);
            alert(`Error  ${error}`);
        });
    };



    return (
        <div>
            <Header />
            <div style={{ margin: "20px", backgroundColor: "#f5f5f5", padding: "20px", borderRadius: "10px" }}>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                    <div style={{ paddingLeft: '20px' }}>
                        <h3>Customise Product Inquiry</h3>
                    </div>

                    <div>
                        <button style={{ borderRadius: '6px', borderColor: "#ECA6A6", backgroundColor: '#ECA6A6', color: "white" }}>
                            <Link to="/my_inquiries" style={{ textDecoration: 'none', color: 'white' }}>
                                <i className="bi bi-clock-history"></i>
                            </Link>
                        </button>
                    </div>

                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>


                    <div style={{ margin: '20px', padding: '20px', minWidth: '35%', backgroundColor: 'white', borderRadius: '10px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>

                        <form onSubmit={handleSubmit}>

                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: "10px" }}>
                                <label htmlFor="weight_id">Weight (in grams)</label>
                                <input type="number" id="weight_id" name="weight" value={inquryData.weight} onChange={handleInquryData} required />
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: "10px" }}>
                                <label htmlFor="metal_id">Metal</label>
                                {/* <input type="text" id="metal_id" name="metal" value={inquryData.metal} onChange={handleInquryData} required /> */}
                                <select id="metal_id" name="metal" value={inquryData.metal} onChange={handleInquryData} required style={{ height: "30px" }}>
                                    <option value="">Select Metal</option>
                                    <option value="Gold">Gold</option>
                                    <option value="Silver">Silver</option>
                                    <option value="Platinum">Platinum</option>
                                    <option value="Diamond">Diamond</option>
                                </select>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: "10px" }}>
                                <label htmlFor="purpose_id">Purpose</label>
                                <textarea id="purpose_id" name="purpose" value={inquryData.purpose} onChange={handleInquryData} required></textarea>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: "10px" }}>
                                <label htmlFor="description_id">Description</label>
                                <textarea id="description_id" name="description" value={inquryData.description} onChange={handleInquryData}></textarea>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: "10px" }}>
                                <label htmlFor="image">Upload Image</label>
                                <input type="file" multiple id="image" accept="image/*" onChange={handleImageUpload} />
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: "10px" }}>
                                <br />
                                <button type="submit" style={{ borderRadius: '6px', borderColor: "#ECA6A6", backgroundColor: '#ECA6A6', color: "white" }}>Submit</button>
                            </div>
                        </form>

                    </div>


                    <div style={{ display: "flex", margin: "20px", padding: "20px", minWidth: "60%", justifyContent: "space-evenly", alignItems: "center", flexWrap: "wrap", backgroundColor: "white", borderRadius: "10px" }}>
                        {Array.isArray(inquryData.urls) && inquryData.urls.length > 0 ? (
                            inquryData.urls.map((image, index) => (
                                <div key={index} style={{ width: "150px", height: "150px", objectFit: "cover", borderRadius: "6px", margin: "5px" }}>
                                    <img
                                        src={ImageBaseUrl + image}
                                        alt={`Preview ${index + 1}`}
                                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                    />
                                </div>
                            ))
                        ) : (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                                <p>No images uploaded yet.</p>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Inquiry;