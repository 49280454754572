    import React, { useContext, useEffect, useState } from "react";
    import Header from "./Layouts/Header";
    import axios from "axios";
    import { BaseUrl, ImageBaseUrl } from "../App";
    import { AuthContext } from "../navigation/AuthProvider";


const MyInquies = () => {
    const { userToken } = useContext(AuthContext);

    const [inquries, setInquries] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedInquiry, setSelectedInquiry] = useState({});

    const getInquries = async () => {
        const reqBody = {
            user_id: userToken
        }
        await axios.post(`${BaseUrl}/get_all_inquiry_by_user_id`, reqBody).then((res) => {
            console.log("Inquries::::::", res.data);
            setInquries(res.data.inquiries);
        }).catch((error) => {
            console.log("Error in Fetching Inquries", error);
        });
    }

    const openModal = (inquiry) => {
        setIsModalOpen(true);
        setSelectedInquiry(inquiry);
    }

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedInquiry({});
    }

    useEffect(() => {
        getInquries();
    }, []);

    return (
        <div>
            <Header />
            <div>

                <div>
                    <table style={{ justifySelf: "center", width: '80%', borderCollapse: 'collapse', marginTop: '20px', backgroundColor: '#fff', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px', overflow: 'hidden' }}>
                        <thead style={{ backgroundColor: '#f5f5f5' }}>
                            <tr>
                                <th style={{ padding: '12px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Weight</th>
                                <th style={{ padding: '12px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Metal</th>
                                <th style={{ padding: '12px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>Price</th>
                                <th style={{ padding: '12px', textAlign: 'left', borderBottom: '1px solid #ddd', textAlignLast: "center" }}>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                inquries.map((inquiry, index) => {
                                    return (
                                        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#fff' }}>
                                            <td style={{ padding: '12px', borderBottom: '1px solid #ddd' }}>{inquiry.weight}</td>
                                            <td style={{ padding: '12px', borderBottom: '1px solid #ddd' }}>{inquiry.metal}</td>
                                            <td style={{ padding: '12px', borderBottom: '1px solid #ddd' }}>{inquiry.price ? inquiry.price : "N/A"}</td>
                                            <td
                                                style={{ padding: '12px', borderBottom: '1px solid #ddd', textAlign: "center" }}
                                                onMouseEnter={(e) => e.target.style.color = '#ECA6A6'}
                                                onMouseLeave={(e) => e.target.style.color = 'black'}
                                                onClick={() => openModal(inquiry)}
                                            >
                                                <i className="bi bi-info-circle"></i>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>

                    {isModalOpen && (
                        <div style={{ position: "fixed", top: "0", left: "0", width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>

                            <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", gap: "20px", backgroundColor: "#ECA6A6", padding: "20px", borderRadius: "8px", display: "flex", flexDirection: "row", alignItems: "center", height: "80%", width: "80%", overflow: "hidden" }}>

                                <span
                                    style={{position: "absolute",top: "20px",right: "25px", fontSize: "20px",cursor: "pointer",color: "#333",fontWeight: "bold",transition: "color 0.3s ease"}}
                                    onClick={closeModal}
                                    onMouseEnter={(e) => e.target.style.color = "red"}
                                    onMouseLeave={(e) => e.target.style.color = "#333"}
                                >✖</span>

                                <div style={{ backgroundColor: "#fff", padding: "20px", borderRadius: "8px", width: "50%", height: "100%", overflowY: "auto" }}>
                                    <p><strong>Weight:</strong> {selectedInquiry?.weight} g</p>
                                    <p><strong>Metal:</strong> {selectedInquiry?.metal}</p>
                                    <p><strong>Price:</strong> {selectedInquiry?.price || "Not updated by seller yet"}</p>
                                    <p><strong>Purpose:</strong> {selectedInquiry?.purpose}</p>
                                    <p><strong>Description:</strong> {selectedInquiry?.description}</p>
                                    <p><strong>Reply:</strong> {selectedInquiry?.message}</p>
                                </div>

                                <div style={{ backgroundColor: "#fff", borderRadius: "8px", width: "50%", height: "100%", display: "flex", justifyContent: "space-evenly", alignItems: "center", flexWrap: "wrap", overflowY: "auto" }}>

                                    {selectedInquiry.urls.length > 0 ? (selectedInquiry.urls.map((image, index) => (
                                        <div>
                                            <img
                                                src={ImageBaseUrl + image}
                                                alt={`Preview ${index + 1}`}
                                                style={{ maxWidth: "150px", maxHeight: "150px", objectFit: "cover" }}
                                            />
                                        </div>
                                    ))) : (
                                        <div style={{ flexWrap: "wrap", display: "flex", justifyContent: "space-evenly", alignItems: "center", width: "100%", height: "100%" }}>
                                            <p>No Images</p>
                                        </div>
                                    )}

                                </div>
                            </div>

                        </div>
                    )}
                </div>
            </div>
        </div>
    )
};

export default MyInquies;